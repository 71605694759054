<template>
    <div>
        <div class="selectMenu px-3" role="button" @click="selectMenu(value)">
            <span v-if="depth">
                <span v-for="item in depth" :key="item">
                    &nbsp; &nbsp;&nbsp; 
                </span>    
            </span>{{ text }}
        </div>
        <div v-if="submenus.length">
            <tree-select-options
                v-for="submenu in submenus"
                :key="submenu._id"
                :submenus="submenu.submenus"
                :text="submenu.text"
                :value="submenu.value"
                :depth="depth + 1"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'tree-select-options',
    props: [
        'submenus', 'text', 'value', 'depth'
    ],
    methods: {
        selectMenu(value) {
            this.$root.$emit('selectMenu',value)
        }
    }
}
</script>

<style scoped>
    .selectMenu:hover{
        background-color: #0984e3;
        color: #fff;
    }
</style>